import React from "react";
import { Tabs } from "antd";
import { SINGLE_TAB } from "../../utils";

const { TabPane } = Tabs;

interface Props {
  onTabChange: (key: string) => void;
  selectedTab: string;
}

export const TaskSingleTabs = ({ onTabChange, selectedTab }: Props) => {
  return (
    <div>
      <Tabs className="tabs" onChange={onTabChange} activeKey={selectedTab}>
        {Object.values(SINGLE_TAB).map((key) => (
          <TabPane tab={key} key={key} />
        ))}
      </Tabs>
    </div>
  );
};
